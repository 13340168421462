<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                :title="$route.params.discountID ? discount.name : 'Новая скидка'"
                left-btn="left-big"
                :show-save-btn="!authUser.read_only && discount.name && conditions.length"
                @click:left="$router.push({ name: 'discounts' })"
                @click:save="$route.params.discountID ? updateDiscount($route.params.discountID) : createDiscount()"
            />
            <div class="tabs__list">
                <TabItem :active="activeTab === 0" @click="activeTab = 0">
                    Основное
                </TabItem>
                <TabItem :active="activeTab === 1" @click="activeTab = 1">
                    Товары
                </TabItem>
            </div>
            <div
                class="page-container page-container--with-tabs"
                :class="{'page-container--with-fab': !authUser.read_only && $route.params.discountID}"
            >
                <div v-show="activeTab === 0" class="tabs__content">
                    <div class="page-columns">
                        <div class="page-big-column">
                            <Row>
                                <Col>
                                    <v-menu ref="date" offset-y :close-on-content-click="false" transition="scale-transition" max-width="290px" min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <InputCustom
                                                :value="datepickerFormatted"
                                                label="Дата"
                                                placeholder="Выберите период"
                                                left-icon="calendar"
                                                clearable
                                                readonly
                                                v-on="on"
                                                @click:clear="datepicker = []"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="datepicker"
                                            no-title
                                            range
                                            first-day-of-week="1"
                                            @input="() => {
                                                if (datepicker[0] && datepicker[1]) {
                                                    $refs.date.save(datepicker);
                                                }
                                            }"
                                        />
                                    </v-menu>
                                </Col>
                                <Col>
                                    <InputCustom v-model="discount.name" label="Название" />
                                </Col>
                                <Col>
                                    <InputSelect
                                        v-model="app.selected"
                                        :options="app.types"
                                        label="Тип скидки"
                                        :disabled="group !== 'supervisor'"
                                    />
                                </Col>
                                <Col>
                                    <InputCustom
                                        v-model="app.amount"
                                        label="Величина скидки:"
                                        :disabled="!!$route.params.ID && group !== 'supervisor'"
                                        :error="(error && error.amount_perc) || (error && error.amount_rub) ? 'Ошибка при сохранении' : null"
                                    />
                                </Col>
                                <!--<Col>
                                    <InputCustom
                                        v-model.number="discount.max_amount_rub"
                                        label="Максимальная величина скидки (руб):"
                                        :disabled="!!$route.params.ID && group !== 'supervisor'"
                                        :error="error && error.max_amount_rub && error.max_amount_rub[0] === 'Must be at least 0.' ?
                                            'Значение должно быть больше нуля' : error && error.max_amount_rub ? 'Ошибка поля при сохранении' : ''
                                        "
                                    />
                                </Col>-->
                                <Col v-if="!this.$route.params.discountID">
                                    <InputSelect
                                        v-model="selectedShop"
                                        :options="[{ id: null, address: 'Не выбран', icon: 'store' }, ...shops.map(item => ({ ...item, icon: item?.type?.icon_path || '' }))]"
                                        label="Витрина магазина"
                                        input-text="address"
                                        icon="store"
                                        @input="toggleShop"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div class="page-small-column page-small-column--mobile-top">
                            <div class="checkbox-group">
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="discount.is_active"
                                        label="Активна"
                                        @change="discount.is_active = !discount.is_active"
                                    />
                                </div>
                            </div>
                            <Button
                                v-if="!authUser.read_only && $route.params.discountID"
                                class="mt-4"
                                block compact text
                                icon-left="trash"
                                @click.prevent="isShowConfirmDeleteModal = true"
                            >
                                Удалить скидку
                            </Button>
                        </div>
                    </div>
                </div>
                <div v-show="activeTab === 1" class="tabs__content">
                    <div class="page-columns">
                        <div class="page-big-column">
                            <div v-if="conditions && conditions.length" class="listing-table">
                                <div class="listing-table__row listing-table__row--head">
                                    <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                                    <div class="listing-table__col pl-0">Наименование</div>
                                    <div class="listing-table__col" style="width: 70px; flex: 0 0 auto;" />
                                </div>
                                <div
                                    v-for="(good, index) in conditions"
                                    :key="index"
                                    class="listing-table__row"
                                >
                                    <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                        <div class="listing-table__image">
                                            <img :src="good.images.length && good.images[0].image ? good.images[0].image.preview : require('@/assets/img/flower.svg')" :alt="good.name">
                                        </div>
                                    </div>
                                    <div
                                        class="listing-table__col listing-table__col--mobile-inline"
                                        :class="{'pt-0 mr-0': width <= 1024}"
                                        :style="width > 1024 ? 'width: calc(100% - 112px)' : 'width: calc(100% - 68px)'"
                                    >
                                        <div>
                                            <div class="listing-table__product-name">
                                                <span>{{ good.name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="width <= 1024 && group !== 'florist' && group !== 'courier'"
                                        class="listing-table__col listing-table__col--mobile-bordered pt-0"
                                    />
                                    <div
                                        v-if="group !== 'florist' && group !== 'courier'"
                                        class="listing-table__col listing-table__col--mobile-inline ml-a"
                                        :style="width > 1024 ? 'width: 70px; flex: 0 0 auto;' : ''"
                                    >
                                        <v-btn icon @click="$store.dispatch('discount/DELETE_DISCOUNT', good)">
                                            <v-icon color="error">mdi-delete</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="page-small-column page-small-column--mobile-top">
                            <button
                                v-if="$route.params.discountID || !selectedShop"
                                type="button"
                                class="edit-list-button"
                                @click.prevent="isModalOpen = true"
                            >
                                <div class="edit-list-button__icon">
                                    <Icon>edit-2</Icon>
                                </div>
                                Изменить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <UniversalModal v-model="isModalOpen" size="full">
            <DiscountsModal @closeModal="isModalOpen = false" />
        </UniversalModal>
        <MiniModal
            v-model="isShowConfirmDeleteModal"
            :title="`Удалить скидку?`"
            additional-btn-text="Отмена"
            main-btn-text="Удалить"
            @additionalBtnClick="isShowConfirmDeleteModal = false"
            @mainBtnClick="deleteDiscount"
        />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import { deleteDiscount } from '@/api/discounts';
import { getGoods, createDiscountCeleryTask } from '@/api/django';

import DiscountsModal from '@/components/app/discounts/modal/DiscountsModal';
import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'TheDiscountCreate',
    metaInfo() {
        return { title: this.$route.params.discountID ? `Скидка ${this.discount.name}` : 'Новая скидка' }
    },
    components: { DiscountsModal, MiniModal },
    data() {
        return {
            discount: {
                activity_start: '',
                activity_end: '',
                name: '',
                is_active: true,
                is_for_promocode: false,
                amount_perc: 0,
                amount_rub: 0,
                conditions: [],
                max_amount_rub: null,
            },
            app: {
                selected: 0,
                amount: 0,
                types: [
                    {
                        id: '1',
                        name: 'В процентах',
                        type: '%'
                    },
                    {
                        id: '2',
                        name: 'В рублях',
                        type: 'rub'
                    },
                ],
            },
            datepicker: [],
            selectedShop: null,
            showcaseCategoryID: null,
            activeTab: 0,
            isModalOpen: false,
            isShowConfirmDeleteModal: false,
            error: null
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            conditions: 'discount/goods_list',
            preloader: 'ui/preloader',
            authUser: 'auth/user',
            shops: 'data/shops',
            categories: 'data/categories',
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        datepickerFormatted() {
            if (this.datepicker.length > 1) {
                return this.datepicker.map(i => this.moment(i).format('DD.MM.YYYY')).join(' - ')
            }
            return ''
        },
    },
    watch: {
        'app.selected'(value) {
            if (value === '1') {
                this.discount.amount_perc = this.app.amount;
                this.discount.amount_rub = 0;
            }
            if (value === '2') {
                this.discount.amount_rub = this.app.amount;
                this.discount.amount_perc = 0;
            }
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            if (!this.shops.length) {
                this.$store.dispatch('data/LOAD_SHOPS', JSON.parse(localStorage.getItem("user")).city);
            }

            this.findShowcaseCategoryID();

            if (this.$route.params.discountID) {
                const discount = await this.$store.dispatch('discount/GET_DISCOUNT_DATA', this.$route.params.discountID);
                this.discount = discount
                this.app.amount = discount.amount_perc || discount.amount_rub;
                this.app.selected = discount.amount_perc ? '1' : '2';

                if (this.discount.activity_start && this.discount.activity_end) {
                    this.datepicker = [
                        this.discount.activity_start.split('.').reverse().join('-'),
                        this.discount.activity_end.split('.').reverse().join('-')
                    ]
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false)
        }
    },
    destroyed() {
        this.$store.commit('equipments/CLEAR_EQUIPMENTS');
        this.$store.commit('discount/CLEAR_GOODS_LIST');
    },
    methods: {
        async createDiscount() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                if (this.app.selected === '1') {
                    this.discount.amount_perc = this.app.amount;
                }
                if (this.app.selected === '2') {
                    this.discount.amount_rub = this.app.amount;
                }

                const data = {
                    ...this.discount,
                    activity_start: this.datepicker[0] ? this.moment(this.datepicker[0]).format('YYYY-MM-DD') : null,
                    activity_end: this.datepicker[1] ? this.moment(this.datepicker[1]).format('YYYY-MM-DD') : null
                }

                if (!data.max_amount_rub) {
                    delete data.max_amount_rub;
                }

                const response = await this.$store.dispatch('discount/CREATE_DISCOUNT', data);

                // Костыль для бэка. Должна запускаться задача на проверку дат активности скидки
                await createDiscountCeleryTask(response.id, {
                    activity_start: data.activity_start,
                    activity_end: data.activity_end
                })

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Скидка сохранена', type: 'success' })
                this.$router.push({ name: 'discounts' });
            } catch (error) {
                console.log(error);
                this.error = error.data;
                if (this.error._schema[0]) {
                    this.$store.commit('ui/add_item_in_system_notifications', { message: this.error._schema[0], type: 'error' })
                }
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async updateDiscount() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                this.setTypeDiscount();

                if (this.app.selected === '1') {
                    this.discount.amount_perc = this.app.amount;
                    this.discount.amount_rub = 0;
                }
                if (this.app.selected === '2') {
                    this.discount.amount_rub = this.app.amount;
                    this.discount.amount_perc = 0;
                }

                const data = { ...this.discount };
                delete data.activity_start;
                delete data.activity_end;

                if (!data.max_amount_rub) {
                    delete data.max_amount_rub;
                }

                await this.$store.dispatch('discount/UPDATE_DISCOUNT', data);

                await createDiscountCeleryTask(this.discount.id, {
                    activity_start: this.datepicker[0] ? this.moment(this.datepicker[0]).format('YYYY-MM-DD') : null,
                    activity_end: this.datepicker[1] ? this.moment(this.datepicker[1]).format('YYYY-MM-DD') : null
                })
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Скидка сохранена', type: 'success' })
                this.$router.push({ name: 'discounts' });
            } catch (error) {
                console.log(error);
                this.error = error.data;
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При сохранении произошла ошибка', type: 'error' })
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async deleteDiscount() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.isShowConfirmDeleteModal = false;
                this.$store.commit('ui/SET_PRELOADER', true);

                await deleteDiscount(this.$route.params.discountID);
                this.$router.push({ name: 'discounts' });
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Скидка удалена', type: 'success' })
            } catch (error) {
                console.log(error);
                this.error = error.data;
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При удалении произошла ошибка', type: 'error' })
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        setTypeDiscount() {
            if (this.discount.amount_rub !== 0) {
                this.app.selected = '2';
                return this.app.types[1].id;
            }
            if (this.discount.amount_perc !== 0) {
                this.app.selected = '1';
                return this.app.types[0].id;
            }
            return 0
        },
        async toggleShop() {
            this.$store.commit('discount/CLEAR_GOODS_LIST');
            if (this.selectedShop) {
                const filters = {
                    limit: 9999,
                    category: this.showcaseCategoryID,
                    is_deleted: 'False',
                    shop: this.selectedShop
                }
                const { results } = await getGoods(filters);
                results.forEach(item => this.$store.dispatch('discount/EVENT_HANDLER', item));
            }
        },
        findShowcaseCategoryID() {
            let interval = setInterval(() => {
                if (this.categories.length) {
                    clearInterval(interval)
                    let showcaseCat = [];
                    const filteredCat = this.categories.filter((category) => category.type === 'bouquet');
                    if (filteredCat) {
                        filteredCat.forEach((item) => {
                            if (item.childrens && item.childrens.length) {
                                item.childrens.forEach((child) => showcaseCat.push(child));
                            } else {
                                showcaseCat.push(item);
                            }
                        });
                    }
                    showcaseCat = showcaseCat.find((item) => item.for_showcase === true);
                    this.showcaseCategoryID = showcaseCat.id;
                }
            }, 500)
        },
        moment
    }
};
</script>
