import { render, staticRenderFns } from "./TheOrderCreate.vue?vue&type=template&id=4ff5b474&"
import script from "./TheOrderCreate.vue?vue&type=script&lang=js&"
export * from "./TheOrderCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports