<template>
    <transition name="slide-up">
        <div
            v-if="notifications && notifications.length && logged"
            class="notifications-wrap notif__popup notif"
        >
            <div v-for="(message,index) in notifications" :key="message.id" class="notification-popup" @click="pushToPath(message)">
                <div class="notification-popup-inner">
                    <div class="notification-popup-message mr-6" :class="{'is-error': error}">
                        <div class="notification__text-wrap">
                            <!-- <div class="notification__time">{{ message.datetime ? dateFormat(new Date(message.datetime),'hours-ru') : 'Время'}}</div> -->
                            <!-- <div class="notification__header">
                                <div class="notification__title">{{ message.title ? message.title : 'Заголовок уведомления'}} :</div>
                            </div> -->
                            <div
                                class="notification__text"
                                :class="{full: opened.includes(message.id)}"
                                v-html="message.text ? message.title + ': ' + message.text : 'Текст уведомления'"
                            />
                        </div>
                        <!-- <v-btn icon class="ml-a mr-4"  @click.stop="openHandler(message.id)" style="margin-left: 5px">
                            <v-icon color="#96A5B9" class="notif__shev" :class="{open: opened.includes(message.id)}">mdi-chevron-down</v-icon>
                        </v-btn> -->
                    </div>
                    <span class="notification__read ml-a" @click.prevent="closeNotification(message.id,index)">
                        {{ message.order_id ? 'Перейти' : 'Прочитать' }}
                    </span>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { date } from '@/utils/format';

export default {
    name: 'TheNotifications',
    data() {
        return {
            opened: []
        }
    },
    computed: {
        ...mapGetters({
            notifications: 'autoNotification/message',
            error: 'ui/error',
            logged: 'auth/logged',
            width: 'ui/width'
        }),
    },
    methods: {
        dateFormat(val, type) {
            return date(val, type);
        },
        closeNotification(id, index) {
            this.$store.dispatch('autoNotification/READ_NOTIFICATION', { id, index });
        },
        openHandler(id) {
            if (this.opened.includes(id)) {
                this.opened = this.opened.filter((a) => a !== id);
            } else {
                this.opened.push(id);
            }
        },
        pushToPath(message) {
            if (message.order_id) {
                this.$router.push(`/orders/${message.order_id}`);
            }
        }
    },
};
</script>

<style lang="sass">
    .notifications-wrap
        position: fixed
        top: 16px
        left: 16px
        display: flex
        flex-direction: column-reverse
        width: 50%
        max-width: 50%
        max-height: 325px
        overflow: hidden
        padding: 2px 16px 10px 0
        z-index: 9999
        transition: 0.3s
        &.left-offset
            left: 296px
        @media screen and (max-width: 991px)
            width: calc(100% - 16px)
            max-width: 100%

    .notification-popup
        position: relative
        display: flex
        width: 100%
        min-height: 48px
        background-color: #676767
        // box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14)
        border-radius: 4px
        z-index: 1000
        &:not(:last-child)
            margin-top: 10px

    .notification-popup-close
        position: absolute
        top: 0
        right: -20px
        cursor: pointer
        &:hover svg path
            fill: #000

    .notification-popup-inner
        display: flex
        align-items: center
        width: fit-content
        max-width: 100%
        padding: 4px 15px

    .notification__header
        width: 100%
        display: flex
        align-items: center
        justify-content: space-between
        margin-right: 5px

    .notification-popup-message
        position: relative
        width: calc(100% - 100px)
        height: 100%
        display: flex
        align-items: center
        font-family: Roboto
        font-style: normal
        font-weight: normal
        font-size: 16px
        line-height: 24px
        letter-spacing: 0.15px
        color: rgba(255, 255, 255, 0.87)
    .notification__text
        max-width: 100%
        font-size: 16px
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        word-break: break-word
        &.full
            overflow: initial
            text-overflow: initial
            white-space: initial
            word-break: break-word
        & a
            color: #95c5ff!important

    .notification__read
        font-family: Roboto
        font-style: normal
        font-weight: 500
        font-size: 14px
        text-align: center
        letter-spacing: 1.25px
        text-transform: uppercase
        cursor: pointer
        color: #5a9bf1
        white-space: nowrap

    .notif__shev.open
        transform: rotate(180deg)

    .notification__text-wrap
        max-width: 100%
        display: flex

    .notification__time
        width: 100%
    .notifications-wrap.notif__popup.notif
        .notification-popup
            width: fit-content
            max-width: 100%
            @media screen and (max-width: 1023px)
                width: 100%
        .notification-popup-message
            width: 100%
            max-width: calc(100% - 113px)

</style>
