/* eslint-disable */
import {
    fetchDiscountsID,
    createDiscount,
    updateDiscount
} from '@/api/discounts';

import { getGoodsByList } from '@/api/django';

const state = {
    discount: {},
    goods_list: [],
};

const mutations = {
    SET_GOODS_LIST(state, goods_list) {
        state.goods_list = goods_list;
    },
    ADD_GOOD_TO_GOODS_LIST(state, { ...good }) {
        state.goods_list.push({ ...good });
    },
    REMOVE_GOOD_FROM_GOODS_LIST(state, good) {
        state.goods_list = state.goods_list.filter(item => item.vendor_code !== good.vendor_code);
    },
    CLEAR_GOODS_LIST(state) {
        state.goods_list = [];
    },
    DELETE_DISCOUNT_ITEM(state, good) {
        state.goods_list = state.goods_list.filter(item => item.vendor_code !== good.vendor_code);
    },
};

const actions = {
    async GET_DISCOUNT_DATA({ commit }, id) {
        try {
            const discount = await fetchDiscountsID(id);
            const vendor_codes = discount.conditions.filter(item => item.field === 'vendor_code').map(el => el.value).join()
            if (vendor_codes.length) {
                const goods = await getGoodsByList(vendor_codes)
                commit('SET_GOODS_LIST', goods.results);
            }
            return discount;
        } catch (error) {
            console.log(error);
        }
    },
    async CREATE_DISCOUNT({ getters }, discount) {
        try {
            const goods = getters['goods_list'].map(item => {
                return {
                    field: 'vendor_code',
                    value: `${item.vendor_code}`,
                    operator: '='
                }
            })
            return await createDiscount({ ...discount, conditions: goods })
        } catch (error) {
            console.log(error);
            throw error
        }
    },
    async UPDATE_DISCOUNT({ commit, getters }, discount_data ) {
        try {
            let discount = { ...discount_data }
            delete discount.conditions
            const goods = getters['goods_list'].map(item => {
                return {
                    field: 'vendor_code',
                    value: `${item.vendor_code}`,
                    operator: '='
                }
            })
            return await updateDiscount(discount.id, { ...discount, conditions: goods })
        } catch (error) {
            console.log(error)
            throw error
        }
    },
    EVENT_HANDLER({ commit, getters }, good) {
        if (getters['selected'](good.vendor_code)) {
            commit('REMOVE_GOOD_FROM_GOODS_LIST', good);
        } else {
            commit('ADD_GOOD_TO_GOODS_LIST', good)
        }
    },
    DELETE_DISCOUNT({ commit }, good) {
        commit('DELETE_DISCOUNT_ITEM', good);
    }
};

const getters = {
    discount: state => state.discount,
    goods_list: state => state.goods_list,
    selected: state => id => !!state.goods_list.find(good => good.vendor_code === id)
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
