/* eslint-disable */
import axios from 'axios';

import store from '../store';
import router from '../router';

import token from '../utils/token';

import { responseErrorHandler, getNewAccessToken } from './auth'

const baseURL = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_HOST_MAIN;

const instance = axios.create({
    baseURL
});

instance.interceptors.request.use(
    async function(config) {
        store.dispatch('ui/startLoading', true);

        if (config._retry) {
            await getNewAccessToken();
        }

        if (token()) {
            config.headers['Authorization'] = token();
        }

        return config;
    },
    function(error) {
        const { status, data } = error.response;
        return Promise.reject({ status, data });
    }
);

instance.interceptors.response.use(
    function(response) {
        store.dispatch('ui/stopLoading', false);
        return response.data;
    },
    async function(error) {
        store.dispatch('ui/stopLoading', false);
        // if (error.response && error.response.status === 401) {
        //     store.commit('auth/REMOVE_DATA_AUTH');
        //     router.push('/');
        // }
        try {
            const res = await responseErrorHandler(error, instance);
            return res;
        } catch (err) {
            if (err.response) {
                const { status, data } = err.response;
                console.log({ status, data });
                return Promise.reject({ status, data });
            } else {
                return Promise.reject(err);
            }
        }
    }
);

/* BEGIN API PROFILE */
export function getProfileData() {
    return instance('/profile/');
}

export function patchProfile(data) {
    return instance({
        method: 'patch',
        url: '/profile/',
        data
    });
}
/* END API PROFILE */



/* BEGIN API USERS */
export function createUser(data) {
    return instance({
        method: 'post',
        url: '/users/',
        data
    });
}

export function updateUser(id, data, headers = {}) {
    return instance({
        method: 'patch',
        url: `/users/${id}/`,
        data,
        headers
    });
}

export function deleteUser(id) {
    return instance.delete(`/users/${id}/`);
}

export function changePasswordUser(id, data) {
    return instance({
        method: 'post',
        url: `/users/${id}/change_password/`,
        data
    });
}

// export function getAllUsers(mode) {
//     if (mode === 'staff') {
//         return instance('/users/?is_staff=True');
//     } else if (mode === "customers") {
//         return instance('/users/?is_staff=False');
//     } else {
//         return instance('/users/');
//     }
// }

export function getAllUsers(params) {
    return instance({
        method: 'get',
        url: `/users/`,
        params
    });
}

export function getUserGroups() {
    return instance('/users/groups/');
}

export function getUserData(id) {
    return instance(`/users/${id}/`);
}

export function getUserByGroup(group, mode) {
    if (mode && mode == 'all') return instance(`/users/?group=${group}&limit=9999`);
    return instance(`/users/?group=${group}`);
}
export function getUserByPhone(phone, isStaff = false, withoutIsStaff) {
    if (isStaff) {
        return instance(`/users/?search=${phone}&is_staff=True`);
    } else if (withoutIsStaff) {
        return instance(`/users/?search=${phone}`);
    }
    return instance(`/users/?search=${phone}&is_staff=False`);
}

export function getUserAllowense(id) {
    return instance(`/users/${id}/allowances/`)
}

export function getNextPageUsers(limit, offset, group, onlyPersonal) {
    if (group !== null && !onlyPersonal) {
        return instance(`/users/?group=${group}&limit=${limit}&offset=${offset}`);
    } else if (group !== null && onlyPersonal) {
        return instance(`/users/?is_staff=True&group=${group}&limit=${limit}&offset=${offset}`);
    } else if(!group && onlyPersonal) {
        return instance(`/users/?is_staff=True&limit=${limit}&offset=${offset}`);
    } else {
        return instance(`/users/?limit=${limit}&offset=${offset}`);
    }
}
export function getNextPageClients(limit, offset, queryString) {
    return instance(`/users/?is_staff=False&limit=${limit}&offset=${offset}&${queryString}`);
}
export function filteredClients(query) {
    return instance(`/users/?is_staff=False&${query}`);
}
export function getFilterMinMaxValues() {
    return instance(`/users/for_filters/`);
}
export function getUserForNotifications(id) {
    return instance(`/users/?id__in=${id}`);
}
export function getUserForNotificationsPage(id, offset) {
    return instance(`/users/?id__in=${id}&limit=20&offset=${offset}`);
}
export function getCashboxes(shop) {
    return instance(`users/by_shop/${shop}/`);
}
export function getClientsIDs(params = {}) {
    return instance('/user_ids/', { params: { ...params, is_staff: 'False' }});
}
export function getUsersIDs(query = '') {
    return instance(`/user_ids/?is_staff=True&${query}`);
}

export function getAllSalarys(params) {
    return instance({
        method: 'GET',
        url: '/users/salary/',
        params
    });
}

export function downloadSalariesFile(params) {
    return instance({
        method: 'GET',
        url: `/admin/salary/download/`,
        params,
        responseType: 'blob'
    });
}

export function exportSalaryToExcel(id) {
    return instance({
        method: 'GET',
        url: `/admin/salary/download/${id}/`,
        responseType: 'blob'
    });
}

export function getSalaryById(id) {
    return instance(`/users/salary/${id}/`);
}
export function getLastSalary(id) {
    return instance(`/users/${id}/salary/last/`);
}

export function postSalary(id, data) {
    return instance({
        method: 'post',
        url: `/users/${id}/salary/`,
        data,
    });
}

export function patchSalary(salary) {
    return instance({
        method: 'patch',
        url: `/users/${salary.user}/salary/${salary.id}/`,
        data: salary,
    });
}

export function deleteSalary(salary) {
    return instance({
        method: 'delete',
        url: `/users/${salary.user}/salary/${salary.id}/`,
    });
}

export function calculateSalary(id, data) {
    return instance({
        method: 'post',
        url: `/users/${id}/salary/calculate_salary/`,
        data,
    });
}

export function downloadUsers(params) {
    return instance({
        method: 'get',
        url: `/admin/users/download/`,
        params,
        responseType: 'blob'
    });
}

/* END API USERS */



/* BEGIN API CATEGORIES */
export function getAllCategories() {
    return instance('/categories/');
}
export function getCategoryID(id) {
    return instance(`/categories/${id}/`);
}
export function postCategory(data) {
    return instance({
        method: 'post',
        url: `/categories/`,
        data
    });
}
export function putCategoryID(id, data) {
    return instance({
        method: 'put',
        url: `/categories/${id}/`,
        data
    });
}
export function deleteCategoryID(id) {
    return instance({
        method: 'delete',
        url: `/categories/${id}/`,
    });
}
export function fetchCategories(withoutEquip) {
    if (withoutEquip) {
        return instance('/categories/?is_equipment=false');
    } else {
        return instance('/categories/');
    }
}
/* END API CATEGORIES */


export function fetchSources(){
    return instance('/stats/sources/');
}



/* BEGIN API PRODUCTS */
export function getHiddenState(params) {
    if (params && params.mode === 'category') {
        return instance(`/admin/products/is_hidden/?categories_ids=${params.items}`);
    } else if (params && params.mode === 'search') {
        return instance(`/admin/products/is_hidden/?products_ids=${params.items}`);
    } else {
        return instance('/admin/products/is_hidden/');
    }
}
export function toggleHiddenGoods(data) {
    return instance({
        method: 'post',
        url: '/admin/products/is_hidden/',
        data
    });
}

export function getGoods(params) {
    return instance({
        method: 'get',
        url: `/admin/products/`,
        params
    });
}

export function getGoodsByQuery(query) {
    return instance(query);
}
export function getAllGoods(categoryID, search, goodIsTemplate = '') {
    if (categoryID) {
        store.commit("goods/SET_QUERY", `/admin/products/?category=${categoryID}&name=${search}&is_template=${goodIsTemplate}`);
        return instance(`/admin/products/?category=${categoryID}&name=${search}&is_template=${goodIsTemplate}`);
    } else {
        store.commit("goods/SET_QUERY", `/admin/products/?name=${search}&is_template=${goodIsTemplate}`);
        return instance(`/admin/products/?name=${search}&is_template=${goodIsTemplate}`);
    }
}
export function getAllParentsGoods(categoryID) {
    if (categoryID) {
        store.commit("goods/SET_QUERY", `/admin/products/?category__parent_category=${categoryID}`);
        return instance(`/admin/products/?category__parent_category=${categoryID}`);
    } else {
        store.commit("goods/SET_QUERY", `/admin/products/`);
        return instance("/admin/products/");
    }
}
export function getNextPageGoods(limit, offset, category, search, goodIsTemplate) {
    if (category !== null && category.childrens.length === 0) {
        store.commit('goods/SET_QUERY', `/admin/products/?category=${category.id}&limit=${limit}&offset=${offset}&name=${search}&is_template=${goodIsTemplate}`);
        return instance(`/admin/products/?category=${category.id}&limit=${limit}&offset=${offset}&name=${search}&is_template=${goodIsTemplate}`);
    } else if (category !== null && category.childrens.length !== 0) {
        store.commit('goods/SET_QUERY', `/admin/products/?category__parent_category=${category.id}&limit=${limit}&offset=${offset}&name=${search}&is_template=${goodIsTemplate}`);
        return instance(`/admin/products/?category__parent_category=${category.id}&limit=${limit}&offset=${offset}&name=${search}&is_template=${goodIsTemplate}`);
    } else {
        store.commit('goods/SET_QUERY', `/admin/products/?limit=${limit}&offset=${offset}&name=${search}&is_template=${goodIsTemplate}`);
        return instance(`/admin/products/?limit=${limit}&offset=${offset}&name=${search}&is_template=${goodIsTemplate}`);
    }
}

export function getGoodData(vendor_code) {
    return instance(`/admin/products/${vendor_code}/`);
}

export function getProductOrdersHistory(vendor_code, params) {
    return instance({
        url: `/products/${vendor_code}/orders_history/`,
        method: 'get',
        params
    });
}

export function getProductResidueHistory(vendor_code, params) {
    return instance({
        url: `/products/${vendor_code}/residue_history/`,
        method: 'get',
        params
    });
}

export function getGoodsByType(type) {
    return instance(`/admin/products/?type=${type}`);
}

export function getGoodsWithLimitByType(limit, type) {
    return instance(`/admin/products/?type=${type}&limit=${limit}`);
}

export function getNextPageGoodsByType(limit, offset, type) {
    return instance(`/admin/products/?limit=${limit}&offset=${offset}&type=${type}`);
}

export function getGoodsByField(field, value, type, goodIsTemplate) {
    if (typeof type === "number") {
        store.commit("goods/SET_QUERY", `/admin/products/?${field}=${value}&category=${type}&is_template=${goodIsTemplate}`);
        return instance(`/admin/products/?${field}=${value}&category=${type}&is_template=${goodIsTemplate}`);
    } else if (typeof type === "string") {
        store.commit("goods/SET_QUERY", `/admin/products/?${field}=${value}&type=${type}&is_template=${goodIsTemplate}`);
        return instance(`/admin/products/?${field}=${value}&type=${type}&is_template=${goodIsTemplate}`);
    } else {
        store.commit("goods/SET_QUERY", `/admin/products/?${field}=${value}&is_template=${goodIsTemplate}`);
        return instance(`/admin/products/?${field}=${value}&is_template=${goodIsTemplate}`);
    }
}

export function getParentsGoodsByField(field, value, type) {
    if (typeof type === 'number') {
        store.commit('goods/SET_QUERY', `/admin/products/?${field}=${value}&category__parent_category=${type}`);
        return instance(`/admin/products/?${field}=${value}&category__parent_category=${type}`);
    } else if (typeof type === 'string') {
        store.commit('goods/SET_QUERY', `/admin/products/?${field}=${value}&type=${type}`);
        return instance(`/admin/products/?${field}=${value}&type=${type}`);
    } else {
        store.commit('goods/SET_QUERY', `/admin/products/?${field}=${value}`);
        return instance(`/admin/products/?${field}=${value}`);
    }
}

export function getEquipmentGoodsByField(field, value, type) {
    return instance(`/admin/products/?type=${type}&${field}=${value}`)
}

export function getGoodsByList(vendorCodesList) {
    return instance(`/admin/products/?vendor_code__in=${vendorCodesList}&limit=99999`)
}

export function createGood(data) {
    return instance({
        method: 'post',
        url: '/admin/products/',
        data
    });
}

export function updateGood(vendor_code, data) {
    return instance({
        method: 'put',
        url: `/admin/products/${vendor_code}/`,
        data
    });
}

export function deleteGood(vendor_code) {
    return instance({
        method: 'delete',
        url: `/admin/products/${vendor_code}/`
    });
}

export function restoreGood(vendor_code) {
    return instance({
        method: 'patch',
        url: `/admin/products/return/${vendor_code}/`,
        data:  { is_deleted: false }
    });
}

export function updateFieldGood(vendor_code, {...data }) {
    return instance({
        method: 'patch',
        url: `/admin/products/${vendor_code}/`,
        data
    });
}
/* END API PRODUCTS */

/* BEGIN API ORDER AND ORDERS */
export function getOrderData(id) {
    return instance(`/admin/orders/${id}/`);
}
export function getOrdersForUser(id, offset = 0) {
    return instance(`/admin/orders/?user__id=${id}&limit=20&offset=${offset}`);
}

export function getAllOrdersData(params) {
    return instance({
        method: 'get',
        url: `/admin/orders/`,
        params
    });
}

export function filteredOrdersByQuery(query) {
    return instance(`/admin/orders/?${query}`);
}

export function getOrderTimeIntervals(date) {
    return instance(`admin/get_time/?date=${date}`);
}

export function getDeliveryTimes() {
    return instance(`/orders/delivery_time/?get_all=True`);
}

export function getPickupTimes() {
    return instance(`/orders/pickup_time/?get_all=True`);
}

export function createOrder(data) {
    return instance({
        method: 'post',
        url: '/admin/orders/',
        data
    });
}

export function updateOrderData(data, id) {
    return instance({
        method: 'patch',
        url: `/admin/orders/${id}/`,
        data,
    });
}
// export function updateOrderShop(data, id) {
//     return instance({
//         method: 'patch',
//         url: `/admin/orders/${id}/`,
//         data,
//     });
// }
export function unbindOrder(data, id) {
    return instance({
        method: 'patch',
        url: `/admin/orders/${id}/`,
        data
    });
}

export function getOrdersStatuses(query) {
    if (query) {
        return instance(`/admin/orders/statuses/?${query}`);
    } else {
        return instance('/admin/orders/statuses/');
    }
}
export function getOrderStatuses(id) {
    return instance(`admin/orders/${id}/available_statuses/`);
}

export function getOrderHistory(id) {
    return instance({
        method: 'get',
        url: `/admin/orders/${id}/history/`
    })
}

export function patchOrderSet(data, id) {
    return instance({
        method: 'patch',
        url: `/admin/orders/${id}/`,
        data
    })
}

export function getCourierOrder(id, startDate, endDate, is_paid) {
    if (startDate && endDate) {
        return instance(`/admin/orders/courier/?courier_id=${id}&delivery_date_after=${startDate}&delivery_date_before=${endDate}&limit=9999&status__in=6,9&is_paid_for_courier=${is_paid ? is_paid : false}`);
    } else {
        return instance(`/admin/orders/courier/?courier_id=${id}&status__in=6,9&is_paid_for_courier=false`);
    }
}
export function getNextCourierOrder(id, offset) {
    return instance(`/admin/orders/courier/?courier_id=${id}&limit=20&offset=${offset}&status__in=6,9&is_paid_for_courier=false`);
}

export function patchCourierOrders(data) {
    return instance({
        method: 'post',
        url: `/admin/orders/courier/set_paid/`,
        data
    })
}

export function getDeliveryPrice(data) {
    return instance({
        method: 'post',
        url: `/orders/calculate_set_price/`,
        data
    })
}

export function calculateDeliveryPrice(data) {
    return instance({
        method: 'post',
        url: `/orders/calculate_delivery_price/`,
        data
    })
}

export function getBillPDF(id, referer) {
    return instance({
        method: 'GET',
        url: `/orders/${id}/payment_template/?referer=https://${referer}`,
        responseType: 'blob'
    });
}
/* END API ORDER AND ORDERS */


/* BEGIN API CITIES */
export function getShopsInCity(city_id) {
    return instance(`/cities/${city_id}/`);
}

export function getShopsWithOrderCount(status) {
    if (!status) {
        return instance(`/cities/shops/with_orders_amount/`);
    } else {
        return instance(`/cities/shops/with_orders_amount/?order_status=${status}`);
    }
}

export function getShopsWithOrderCountByValue(value) {
    return instance(`/cities/shops/with_orders_amount/?${value}`);
}

export function fetchCities() {
    return instance('/cities/');
}
export function fetchCitiesByName(name) {
    return instance(`/cities/?name=${name}`);
}

export function fetchShops() {
    return instance('/cities/shops/');
}
export function getNextPageShops(limit, offset) {
    return instance(`/cities/shops/?limit=${limit}&offset=${offset}`);
}
export function getShopBalanceHistory(id) {
    return instance(`/cities/shops/${id}/history/`);
}
export function getNextShopBalanceHistory(id, offset) {
    return instance(`/cities/shops/${id}/history/?limit=20&offset=${offset}`);
}
export function fetchShopsTypes() {
    return instance('/cities/shops/types/');
}
/* END API CITIES*/

export function fetchTypesForProducts() {
    return instance('/products/types_list/');
}

export function fetchUnits() {
    return instance('/admin/products/units/');
}

export function fetchOrderID(id) {
    return instance(`/admin/orders/${id}/`);
}

export function updateOrderID(id, data, header = {}) {
    return instance({
        method: 'patch',
        url: `/admin/orders/${id}/`,
        header,
        data
    });
}

export function updateCourierOrderID(id, data, header = {}) {
    return instance({
        method: 'patch',
        url: `/courier/orders/${id}/`,
        header,
        data
    });
}

export function setStatusOrder(status, orderID) {
    return instance({
        method: 'post',
        url: `/orders/${orderID}/change_status/`,
        data: { status: status }
    });
}

export function downloadOrdersFile(params) {
    return instance({
        method: 'GET',
        url: '/orders/download/',
        params,
        responseType: 'blob'
    });
}

export function fetchImageID(method, url = '/product_images/', headers = {}, data = {}) {
    return instance({
        method,
        url,
        headers,
        data,
    });
}

export function patchImageById(method, url = '/product_images/', data) {
    return instance({
        method,
        url,
        data
    })
}

export function deleteImageById(method, url = `/sdsds/${id}/`, headers = {}, data = {}) {
    return instance({
        method,
        url,
        headers,
        data
    });
}

export function fetchEquipmentProduct() {
    return instance('/products/equipment/?limit=9999');
}

export function fetchReviews(params) {
    return instance('/admin/reviews/', { params });
}

export function fetchReviewsID(id) {
    return instance(`/admin/reviews/${id}/`);
}

export function apiEditReview(reviewID, data) {
    return instance({
        method: 'patch',
        url: `/admin/reviews/${reviewID}/`,
        data
    });
}

export function downloadReviews(params) {
    return instance({
        method: 'GET',
        url: `/admin/reviews/download/`,
        params,
        responseType: 'blob'
    });
}

/* BEGIN API PROMOCODES */
export function fetchPromocodes() {
    return instance('/admin/promocodes/?limit=999&offset=0');
}

export function fetchPromocodeID(ID) {
    return instance(`/admin/promocodes/${ID}/`);
}

export function createPromocodeItem(data) {
    return instance({
        method: 'post',
        url: `/admin/promocodes/`,
        data
    });
}

export function updatePromocodeItem(id, data) {
    return instance({
        method: 'patch',
        url: `/admin/promocodes/${id}/`,
        data
    });
}

export function deletePromocodeItem(id) {
    return instance.delete(`/admin/promocodes/${id}/`);
}
/* END API PROMOCODES*/

/* Bonuses */
export function getBonuses() {
    return instance('/admin/bonus/')
}

export function getBonusesById(id) {
    return instance(`/admin/bonus/${id}`)
}

export function createBonus(data) {
    return instance({
        method: 'post',
        url: '/admin/bonus/',
        data
    })
}

export function editBonus(id, data) {
    return instance({
        method: 'patch',
        url: `/admin/bonus/${id}/`,
        data
    })
}

/* Encashments */
export function getEncashments() {
    return instance('/encashments/');
}
export function getEncashmentsForShop(shop) {
    return instance(`/encashments/?shop=${shop}&limit=5`);
}
export function getNextEncashmentsForShop(shop, offset) {
    return instance(`/encashments/?shop=${shop}&limit=5&offset=${offset}`);
}
export function getNextPageEncashments(limit, offset){
    return instance(`/encashments/?limit=${limit}&offset=${offset}`);
}

export function createEncashment(data){
    return instance({
        method: 'post',
        url: '/encashments/',
        data
    });
}

export function getEncashmentData(id){
    return instance(`/encashments/${id}/`);
}
export function editEncashment(id, data){
    return instance({
        method: 'PATCH',
        url: `/encashments/${id}/`,
        data
    });
}
export function updateEncashmentStatus(id, data){
    return instance({
        method: 'POST',
        url: `/encashments/${id}/change_status/`,
        data: data
    });
}

/* Salary changes */
export function getSalarysChanges() {
    return instance('/salary_changes/');
}

export function getNextPageSalarysChanges(limit, offset){
    return instance(`/salary_changes/?limit=${limit}&offset=${offset}`);
}

export function createSalarysChanges(data) {
    return instance({
        method: 'post',
        url: '/salary_changes/',
        data
    })
}

export function editSalarysChanges(id, data) {
    return instance({
        method: 'patch',
        url: `/salary_changes/${id}/`,
        data
    })
}

export function getChangesData(id) {
    return instance(`/salary_changes/${id}/`);
}

export function fetchPaymentMethods(bool) {
    if (bool) {
        return instance('/payment_methods/?is_create=1');
    } else {
        return instance('/payment_methods/');
    }
}
export function fetchPaymentMethod(id) {
    return instance(`/payment_methods/${id}/`);
}
export function savePaymentType(data) {
    return instance({
        method: 'post',
        url: `/payment_methods/`,
        data
    });
}
export function editPaymentType(id, data) {
    return instance({
        method: 'patch',
        url: `/payment_methods/${id}/`,
        data
    });
}
export function removePaymentType(id) {
    return instance.delete(`/payment_methods/${id}/`);
}
export function getNextPagePayment(offset) {
    return instance(`/payment_methods/?limit=20&offset=${offset}`);
}
/*FRANCHISE*/
export function fetchFranchises(params) {
    return instance({
        method: 'get',
        url: `/franchises/`,
        params
    });
}
export function fetchFranchiseData(id) {
    return instance(`/franchises/${id}/`);
}
export function createFranchise(data) {
    return instance({
        method: 'post',
        url: `/franchises/`,
        data
    });
}
export function editFranchise(id, data, header = {}) {
    return instance({
        method: 'patch',
        url: `/franchises/${id}/`,
        header,
        data
    });
}
export function removeFranchise(id) {
    return instance.delete(`/franchises/${id}/`);
}
export function fetchStickers() {
    return instance('/stickers/');
}

export function getFilterMinMaxValuesFranchise() {
    return instance(`/franchises/for_filters/`);
}
export function getFranchiseTarif() {
    return instance(`/franchises/front/`);
}
/* SHOP */
export function getShopData(id) {
    return instance(`/cities/shops/${id}/`);
}
export function createShop(data) {
    return instance({
        method: 'post',
        url: `/cities/shops/`,
        data
    });
}
export function editShop(id, data) {
    return instance({
        method: 'patch',
        url: `/cities/shops/${id}/`,
        data
    });
}
export function removeShop(id) {
    return instance.delete(`/cities/shops/${id}/`);
}

export function getShopBalance() {
    return instance(`/cities/shops/balance/`);
}

/* Content */
export function getSlideData(id) {
    return instance(`/slides/${id}/`);
}
export function getSliderData(id) {
    return instance(`/sliders/${id}/`);
}
export function editSlide(data) {
    return instance({
        method: 'post',
        url: `/slides/`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data
    });
}

/* additional_bill */
export function getBills() {
    return instance('/additional_bill/');
}
export function getBill(id) {
    return instance(`/additional_bill/${id}/request/`);
}
export function createBill(data) {
    return instance({
        method: 'post',
        url: `/additional_bill/`,
        data
    });
}
export function editBill(id, data) {
    return instance({
        method: 'patch',
        url: `/additional_bill/${id}/request/`,
        data
    });
}
export function removeBill(id) {
    return instance.delete(`/additional_bill/${id}/request/`);
}
export function payBill(data) {
    return instance({
        method: 'post',
        url: `/payment/create_additional_bill/`,
        data
    });
}
export function getNextPageBills(limit, offset) {
    return instance(`/additional_bill/?limit=${limit}&offset=${offset}`);
}

/*RETURNS*/
export function getReturns() {
    return instance('/admin/orders/returns/');
}
export function getReturnData(id) {
    return instance(`/admin/orders/returns/${id}/`);
}
export function createReturns(data) {
    return instance({
        method: 'post',
        url: `/admin/orders/returns/`,
        data
    });
}
export function updateReturns(id, data) {
    return instance({
        method: 'patch',
        url: `/admin/orders/returns/${id}/`,
        data
    });
}
export function getNextPageReturns(limit, offset) {
    return instance(`/admin/orders/returns/?limit=${limit}&offset=${offset}`);
}

/*Pages*/
export function getPages() {
    return instance('/admin/pages/');
}
export function getPageData(id) {
    return instance(`/admin/pages/${id}/`);
}
export function createPage(data) {
    return instance({
        method: 'post',
        url: `/admin/pages/`,
        data
    });
}
export function updatePage(id, data) {
    return instance({
        method: 'patch',
        url: `/admin/pages/${id}/`,
        data
    });
}
export function deletePage(id) {
    return instance.delete(`/admin/pages/${id}/`);
}
export function getNextPagePages(offset) {
    return instance(`/admin/pages/?limit=20&offset=${offset}`);
}

// Statistics
export function getStatistics(params) {
    return instance({
        method: 'get',
        url: `/stats/single_franchise/`,
        params
    });
}


export function VKAuth(referer, backURL, returnURL) {
    return instance(`/sync/vk/auth/?referer=https://${referer}/&back_url=${backURL}&return_url=${returnURL}`);
}
export function syncVK(referer) {
    return instance(`/sync/vk/?referer=https://${referer}/`);
}
export function getSyncSettings() {
    return instance(`/sync/settings/?limit=99999`);
}
export function patchSyncSettings(id, data) {
    return instance({
        method: 'patch',
        url: `/sync/settings/${id}/`,
        data
    });
}
export function getSyncAlbums() {
    return instance(`/sync/albums/?limit=9999`);
}
export function patchSyncAlbums(id, data, header = {}) {
    return instance({
        method: 'patch',
        url: `/sync/albums/${id}/`,
        header,
        data
    });
}
export function patchSyncAlbumsStatus(data) {
    return instance({
        method: 'post',
        url: `/sync/albums/is_unload/`,
        data
    });
}

export function exportGoodsToExcel() {
    return instance({
        method: 'get',
        url: `/sync/products/xml/`,
        responseType: 'blob'
    });
}
export function regPhoneConfirm(data) {
    return instance({
        method: 'post',
        url: `/profile/phone_confirm/`,
        data
    });
}
export function regPhoneCheck(data) {
    return instance({
        method: 'post',
        url: `/profile/phone_check/`,
        data
    });
}
export function createSupportImage(data) {
    return instance({
        method: 'post',
        url: `/support_images/`,
        data
    });
}
export function sendSupportMessage(data) {
    return instance({
        method: 'post',
        url: `/support/question/`,
        data
    });
}

//Motivations
export function getMotivations(params) {
    return instance(`/motivations/`, { params } );
}
export function getMotivationItem(id) {
    return instance(`/motivations/${id}/`);
}
export function postMotivations(data) {
    return instance({
        method: 'post',
        url: `/motivations/`,
        data
    });
}
export function patchMotivations(id, data) {
    return instance({
        method: 'patch',
        url: `/motivations/${id}/`,
        data
    });
}
export function deleteMotivations(id) {
    return instance.delete(`/motivations/${id}/`);
}

//Catalogs
export function getProductCatalogs(filters = null) {
    if (filters) return instance(`/product_catalogs/?${filters}`);
    return instance(`/product_catalogs/`);
}
export function getCatalogItem(id) {
    return instance(`/product_catalogs/${id}/`);
}
export function postProductCatalog(data) {
    return instance({
        method: 'post',
        url: `/product_catalogs/`,
        data
    });
}
export function patchProductCatalog(id, data) {
    return instance({
        method: 'patch',
        url: `/product_catalogs/${id}/`,
        data
    });
}
export function deleteProductCatalog(id) {
    return instance.delete(`/product_catalogs/${id}/`);
}
export function importCatalogByID(id) {
    return instance({
        method: 'post',
        url: `/product_catalogs/${id}/start_import/`
    });
}
export function clearCategories() {
    return instance(`/categories/clean_up/`);
}
export function clearGoods() {
    return instance(`/admin/products/clean_up/`);
}

export function getTags() {
    return instance(`/admin/tags/?limit=9999`);
}
export function getTag(id) {
    return instance(`/admin/tags/${id}/`);
}
export function createTag(data) {
    return instance({
        method: 'post',
        url: `/admin/tags/`,
        data
    });
}
export function editTag(id, data) {
    return instance({
        method: 'patch',
        url: `/admin/tags/${id}/`,
        data
    });
}
export function deleteTag(id) {
    return instance.delete(`/admin/tags/${id}/`);
}

export function getFlowerSubscriptionSettings(siteID) {
    return instance(`/productsubscription/${siteID}/settings/`);
}

export function saveFlowerSubscriptionSettings(data, siteID) {
    return instance({
        method: 'patch',
        url: `/productsubscription/${siteID}/settings/`,
        data,
    });
}

export function getFlowerSubscriptions(params) {
    return instance({
        method: 'get',
        url: `/admin/productsubscriptions/`,
        params
    });
}

export function getFlowerSubscription(id) {
    return instance(`/productsubscriptions/${id}/`);
}

export function saveFlowerSubscription(data) {
    return instance({
        method: 'post',
        url: `/admin/productsubscriptions/`,
        data,
    });
}

export function getWishesForProducts(params) {
    return instance({
        method: 'get',
        url: `/admin/wishes/`,
        params
    });
}

export function getWishForProducts(id) {
    return instance(`/admin/wishes/${id}/`);
}

export function getCertificatesTemplates() {
    return instance(`/certificate_images/`);
}

export function getCertificates(params) {
    return instance({
        method: 'GET',
        url: '/certificates/',
        params
    });
}

export function getCertificate(id) {
    return instance(`/certificates/${id}/`);
}

export function createCertificate(data) {
    return instance({
        method: 'post',
        url: `/certificates/`,
        data
    });
}

export function editCertificate(id, data) {
    return instance({
        method: 'patch',
        url: `/certificates/${id}/`,
        data
    });
}

export function createCertificatePaymentLink(data) {
    return instance({
        method: 'post',
        url: `/payment/create_certificate/`,
        data
    });
}

export function deleteCertificate(id) {
    return instance.delete(`/certificates/${id}/`);
}

export function createDiscountCeleryTask(id, data) {
    return instance({
        method: 'put',
        url: `/discount/${id}/`,
        data
    });
}
