<template>
    <transition name="slide-up">
        <!-- <div v-if="!!message" class="app-popup">
            <button type="button" class="app-photo-delete app-button-close" @click="closePopup"/>
            <div class="app-popup-inner">
                <div class="app-popup-message" :class="{'is-error': error}">
                    {{ message }}
                </div>
            </div>
        </div>
        <div v-if="showErrorMessage" class="app-popup">
            <button type="button" class="app-photo-delete app-button-close popup-close" @click="closePopup"/>
            <div v-if="errorMessage === ''" class="app-popup-inner">
                <div class="app-popup-message is-error" style="text-align:center;">
                    Соединение разорвано.<br>Проверяйте заказы вручную!
                </div>
            </div>
            <div v-else class="app-popup-inner">
                <div class="app-popup-message is-error" style="text-align:center;">
                    {{ errorMessage }}
                </div>
            </div>
        </div> -->
        <div
            v-if="!!message || showErrorMessage || reloadMessage"
            class="notifications-wrap notif__popup ml-2 mr-4"
            :class="{'custom-offset-1': notifications.length === 1}"
            style="overflow: initial"
        >
            <div class="notification-popup popup-second" style="border-radius: 0 4px 4px 0 !important">
                <div
                    class="popup-indicator"
                    :class="{'ind-error': showErrorMessage || (reloadMessage && !reloadMessage.startsWith('Импорт'))}"
                />
                <div class="notification-popup-inner">
                    <div
                        class="notification-popup-message"
                        :class="{'is-error': showErrorMessage || (reloadMessage && !reloadMessage.startsWith('Импорт'))}"
                    >
                        <div class="notification__text-wrap" style="width: 100%">
                            <div class="notification__text full" v-html="message ? message : errorMessage ? errorMessage : reloadMessage" />
                        </div>
                        <!-- <v-btn icon class="ml-a mr-4"  @click.stop="openHandler(message.id)" style="margin-left: 5px">
                            <v-icon color="#96A5B9" class="notif__shev" :class="{open: opened.includes(message.id)}">mdi-chevron-down</v-icon>
                        </v-btn> -->
                    </div>
                    <!-- <span class="notification__read ml-a" @click="closePopup">Прочитать</span> -->
                    <span v-if="reloadMessage" class="notification__read ml-6" @click="reloadPage">
                        Обновить - {{ seconds }}
                    </span>
                    <span v-if="reloadMessage" class="notification__read ml-2" @click="clearTimer">
                        <v-icon color="#5a9bf1">mdi-close</v-icon>
                    </span>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex';

let timer;

export default {
    name: 'ThePopup',
    data() {
        return {
            seconds: 7
        }
    },
    computed: {
        ...mapGetters({
            notifications: 'autoNotification/message',
            message: 'ui/message',
            error: 'ui/error',
            showErrorMessage: 'ui/showErrorMessage',
            errorMessage: 'ui/errorMessage',
            width: 'ui/width',
            reloadMessage: 'ui/reloadMessage'
        })
    },
    mounted() {
        if (!this.reloadMessage) {
            setTimeout(() => this.closePopup(), 2000);
        }
        this.setTimer();
    },
    methods: {
        closePopup() {
            this.$store.commit('ui/SET_RELOAD_MESSAGE', '');
            this.$store.commit('ui/HIDE_ERROR_MESSAGE');
            if (this.errorMessage !== '') {
                this.$store.commit('ui/SET_ERROR_MESSAGE', '');
            }
            this.$store.commit('ui/CLOSE_POPUP');
        },
        setTimer() {
            if (this.reloadMessage) {
                timer = setInterval(() => {
                    if (this.seconds) {
                        this.seconds--;
                    } else {
                        this.reloadPage();
                        this.clearTimer();
                    }
                }, 1000);
            }
        },
        reloadPage() {
            window.location.reload();
        },
        clearTimer() {
            clearInterval(timer);
            this.$store.commit('ui/SET_RELOAD_MESSAGE', '');
        }
    }
};
</script>

<style lang="sass" scoped>
    .popup-second
        border-radius: 0 4px 4px 0
        @media screen and (max-width: 1023px)
            width: calc(100% - 8px)

    .popup-indicator
        position: absolute
        width: 8px
        left: -8px
        height: 100%
        border-radius: 4px 0 0 4px
        background-color: #52B687
        &.ind-error
            background-color: #E54B4B
    .app-popup
        position: fixed
        top: 20px
        left: calc(50% - (480px / 2))
        display: flex
        width: 100%
        max-width: 320px
        min-height: 50px
        border-radius: 5px
        background-color: #ffffff
        box-shadow: 0 0 10px 2px rgba(0, 0, 0,.1)
        z-index: 1000
        @media screen and (max-width: 1023px)
            max-width: 280px
            left: 0
            right: 0
            margin: auto

    .app-popup-close
        top: 10px
        right: 10px

    .popup-close
        top: 3px
        right: -6px

    .notification-popup
        border-radius: 0 4px 4px 0

    .app-popup-inner
        flex-grow: 1
        display: flex
        justify-content: center
        align-items: center
        padding: 15px

    .app-popup-message
        font-size: 13px
        color: #28a745
        &.is-error
            color: #dc3545

    .notifications-wrap.notif__popup
        // width: max-content
        width: auto
        transition: 0.3s
        @media screen and (max-width: 991px)
            width: calc(100% - 16px)
        &.custom-offset-1
            transform: translateY(60px)
        .notification-popup-message
            width: 100%
        .notification__text
            width: 100%
            max-width: 100%
            display: -webkit-box
            -webkit-line-clamp: 1
            overflow: hidden
            -webkit-box-orient: vertical

</style>
