import { render, staticRenderFns } from "./Loader.vue?vue&type=template&id=7b5695aa&scoped=true&functional=true&"
var script = {}
import style0 from "./Loader.vue?vue&type=style&index=0&id=7b5695aa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "7b5695aa",
  null
  
)

export default component.exports